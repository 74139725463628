
import { IonPage, IonContent, loadingController, alertController, toastController, IonToggle, IonButton, IonSpinner, IonHeader, IonTitle, IonToolbar, IonBackButton } from '@ionic/vue';
import { defineComponent, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';

/**
 * services
 */

import services from "../../../services";

export default defineComponent({
  components: { IonPage, IonContent, IonToggle, IonButton, IonSpinner, IonHeader, IonTitle, IonToolbar, IonBackButton },
  setup(){
    const router = useRouter();
    const state = reactive({
      form:{
        facebookState: false,
        facebookId: ""
      },
      user: {
        loading: true,
        data: {}
      },
      contentLoading: true
    });

    const presentAlert = async (message: any, callback: any = null) => {
      const alert = await alertController
        .create({
          header: "알림",
          backdropDismiss: false,
          message: message,
          buttons: [
            {
              text: '확인',
              handler: () => {
                if ( callback ) {
                  callback();
                }
              },
            },
          ],
        });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    };

    // const openToast = async (message : string ) string 에러 발생
    const openToast = async (message: string) => {
      const toast = await toastController
        .create({
          message: message,
          duration: 2000
        })
      return toast.present();
    };

    const fetch = async () => {
      await services.authUser().then(
        response => {
          const { data } = response;
          state.form.facebookId = data.facebookId
          if ( 0 < state.form.facebookId.length ) {
            state.form.facebookState = true;
          } else {
            state.form.facebookState = false;
          }

          state.user.data = data;
          state.user.loading = false;
        },
        error => {
          let message = '';
          try {
            message = error.response.data.message;
          } catch (e) {
            message = '';
            console.log(e);
          }
          console.log(message);
          console.log(message);
          openToast(message)
        }
      );

      setTimeout(()=>{
        state.contentLoading = false
      } ,500)
    }
    const onSubmit = async () => {
      const loading = await loadingController
      .create({
          cssClass: 'my-custom-class',
          message: '수정 중입니다.',
      });
      await loading.present();
      const params = {
        facebookId: state.form.facebookState ? state.form.facebookId : "",
      }
      console.log(params)
      await services.userPatch(params).then(

        response => {
          if(response.status == 200) {
            setTimeout(function() {
              loading.dismiss()
              presentAlert('수정이 완료 되었습니다', async () =>{
                window.history.length > 1 ? router.go(-1) : router.push('/')
              });
            }, 1000);
          } else {
            setTimeout(function() {
              loading.dismiss()
            }, 1000);
          }
        },
        error => {
          let message = '';
          try {
            message = error.response.data.message;
          } catch (e) {
            message = '';
            console.log(e);
          }
          console.log(message);
          setTimeout(function() {
            loading.dismiss()
            presentAlert(message);
          }, 1000);
        }
      )
    };

    onMounted(() => {
      fetch();
      console.log('onMounted');
    });

    return { state, onSubmit  }
  }
})
